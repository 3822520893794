////////////////////// FUNZIONE MENU ////////////////////////////// 
function menu() {
    var lungP = $('#elenco_prodotti li').width();
    if (lungP > 400) {
        $('button.add .eti').show();
    }
    else {
        $('button.add .eti').hide();
    }
}




function altezza_contenuti() {
    var hcont = $('#principale').height();
    var foo = $('footer').outerHeight();
    if (hcont < $(window).height()) {
        $('#principale').css({'min-height': $(window).height() - foo});
    }
}


$(document).ready(function () {
    $('.accordion').accordion();

    altezza_contenuti();
    menu();

    $("#shopping-bag").click(function (e) {
        e.preventDefault();
        var carrello = $('#carrello_rapido');
        var bottom = 0;
        if (parseInt(carrello.css("bottom"),10) === 0) {
            bottom = -80;
        }
        carrello.animate(
            { bottom: bottom}
            , 400);
        return false;
    });

    $(".menu_mobile").click(function (e) {
        e.preventDefault();
        $('.overlay-menu').addClass("open");
        return false;
    });


    $(".menu_mobile").click(function (e) {
        e.preventDefault();
        $('.overlay-menu').addClass("open");
        $(this).removeClass("active");
        return false;
    });

    $(".close-menu").on("click", function (e) {
        $('.overlay-menu').removeClass("open");
        $(".menu_mobile").addClass("active");
    });


    $('a.navigation[href^="#"]').on('click', function (e) {
        e.preventDefault();

        var target = this.hash;
        var $target = $(target);

        $('html, body').stop().animate({
            'scrollTop': $target.offset().top
        }, 600, 'swing', function () {
            window.location.hash = target;
        });
    });


});
$(window).resize(function () {
    altezza_contenuti();
    menu();
});
